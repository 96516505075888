import React, { useMemo } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  font-family: "Bitter";
  padding: 0 1em;
  background-color: #fff;
  @media (max-width: 768px) {
    padding: 0;
  }
`

const Frame = styled.div`
  background: #fff;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.3;
  font-weight: bold;
  padding: 1.5rem;
  border: 3px solid #100;
  box-shadow: 0 10px 0 -5px #91d8f7, 0 20px 0 -10px #ef4b53,
    0 30px 0 -16px #dedcb9;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  @media (max-width: 768px) {
    padding: ${props => props.MobilePadding || 0};
  }

  h2 {
    font-size: 1.4rem;
    line-height: 1.3;
    font-weight: 800;
    margin: 0;
  }
`

const Content = styled.div`
  font-family: "Bitter";

  a {
    border-bottom: 2px solid black;
  }
`

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
`

const About = ({ data }) => {
  const howLongIveBeenCoding = useMemo(() => {
    const today = new Date()
    const start = new Date(2013, 9, 23)
    const diff = today - start
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const years = Math.floor(days / 365)
    const months = Math.floor((days % 365) / 30)
    return `${years} years`
  })

  return (
    <Layout>
      <SEO title="About Me" />
      <Container>
        <Wrapper>
          <Fade>
            <Frame MobilePadding="1em">
              <h2>
                I'm a web developer who aims to create experiences that look and
                function beautifully across the web.
              </h2>
            </Frame>
            <Frame>
              <Img
                fluid={data.image.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="That is me"
              />
            </Frame>

            <Content>
              <p>
                <b>A bit more about me, </b> I'm from Brazil 🇧🇷, but I’ve been
                living in Sydney, Australia 🇦🇺 for {howLongIveBeenCoding} years
                and now I’m also Australian. My background is in Tourism. I used
                to code before but it was after a course at General Assembly in
                Sydney that I transitioned my career and today I also mentor new
                software development students.
              </p>
              <p>
                <b>A bit more about work, </b>
                I'm super into tech, design, and how people interact with stuff.
                I really enjoy making slick UIs that let users get the most out
                of an app without worrying about the magic happening behind the
                screen. JavaScript is my go-to language, mainly because the
                community is awesome and it's super versatile. I mostly work
                with React on the frontend, but I'm no stranger to Node either.
                Plus, I'm always down to learn new tech and challenge myself to
                level up!
              </p>
              <p>
                <b>Innovation and passions</b> really drives me, I was lucky to
                work with <Link to="/work">Startups</Link> in areas like Airbnb
                management, mental health, and meditation. Inside a screen
                everything is possible, the most beautiful photos and the
                craziest dreams, whatever the man can think of can be created in
                pixels and that to me is fascinating.
              </p>
            </Content>

            <Frame>
              <Img
                fluid={data.image2.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="That is me"
              />
            </Frame>

            <Content>
              <p>
                A have a few foodie hobbies and I love a classic Brazilian
                BBQ cooked with charcoal and meat on the spit and It's even
                better in Australia where you can find great beaches or even
                offices with this view (Stunning MadeComfy office in Balmain).
                Recently I have also been nurturing a passion for brewing coffee
                - I'm really into trying new tastes and techniques. I grew up
                around coffee farms and now I see everything with different
                eyes.
              </p>
              <p>
                I love a classic Brazilian BBQ cooked with charcoal and meat on
                the spit and It's even better in Australia where you can find
                great beaches or even offices with this view (Stunning MadeComfy
                office in Balmain). Recently I have also been nurturing a
                passion for brewing coffee - I'm really into trying new tastes
                and techniques. I grew up around coffee farms and now I see
                everything with different eyes.
              </p>
            </Content>
          </Fade>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "me-balcony.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "bbq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default About
